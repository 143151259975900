import React from 'react';
import './App.css';

const ConstructionPage = () => {
  return (
    
    <div className="construction-page">
      <div className="cone">
        <pre>
          {`⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠟⠻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠀⠀⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠃⠀⠀⠘⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣶⣶⣶⣶⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⠛⠻⠿⠿⠟⠛⢻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠇⠀⠀⠀⠀⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣄⣀⡀⠀⠀⢀⣀⣠⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⠇⠀⠉⠛⠛⠛⠛⠛⠛⠉⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⠿⠟⠛⢩⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⡉⠛⠻⠿⣿⣿⣿⣿
⣿⣿⣿⣶⣦⣄⡀⠀⢿⣤⡀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣤⡿⠀⢀⣠⣴⣶⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣶⣾⣝⣛⠛⠿⠷⠾⠿⠛⣛⣫⣷⣶⣿⣿⣿⣿⣿⣿⣿⣿
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣶⣤⣤⣶⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
          `}
        </pre>
      </div>
      <h1>Namastek Sitio en Construcción</h1>
      <p>Estamos trabajando para mejorar tu experiencia. ¡Vuelve pronto!</p>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <ConstructionPage />
    </div>
  );
}

export default App;
